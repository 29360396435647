@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

*{
  font-family: 'Poppins', sans-serif;
}


::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: rgba(242, 242, 242, 0.1);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(166, 166, 166, 0.5);
  border-radius: 5px;
  opacity: 0.2;
}

.css-advny6-MuiPopper-root {
  margin-top: 15px !important;
}
.css-x5hfn0-MuiTableCell-root {
  border-bottom: none !important;
}
.css-1yr1g73-MuiDivider-root::before,
.css-1yr1g73-MuiDivider-root::after {
  top: 0 !important;
}

.main_color{
  /* background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(141,190,122,1) 49%, rgba(176,189,98,1) 65%, rgba(253,187,45,1) 100%); */
  background: linear-gradient(0deg, #000040 0%, #000080 49%, #000000 65%);
}

